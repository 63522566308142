import {
    type StoryblokRichTextNodeResolver,
    type StoryblokRichTextNodeTypes,
    type StoryblokRichTextNode,
    type TextNode,
    type MarkNode,
    type LinkNode,
} from '@storyblok/richtext';
type RichTextNode =
    | StoryblokRichTextNode<unknown>
    | TextNode<unknown>
    | MarkNode<unknown>
    | LinkNode<unknown>;

export const getCustomizedResolvers = (): Partial<
    Record<StoryblokRichTextNodeTypes, StoryblokRichTextNodeResolver<unknown>>
> => {
    const resolvers = {
        bullet_list: (node: RichTextNode) => {
            return `<ul class="flex flex-col gap-5 mb-5">${node.children && Array.isArray(node.children) && node.children.length > 1 ? node.children.join('') : node.children}</ul>`;
        },
        ordered_list: (node: RichTextNode) => {
            return `<ol class="flex list-decimal flex-col gap-5 list-inside mb-5" type="styled">${node.children && Array.isArray(node.children) && node.children.length > 1 ? node.children.join('') : node.children}</ol>`;
        },
        list_item: (node: RichTextNode) => {
            return `<li class="flex items-start gap-3 [&_img]:pt-2"><img src="/icons/check-circle-broken.svg" alt="circle" title="circle" type="styled">${node.children && Array.isArray(node.children) && node.children.length > 1 ? node.children.join('') : node.children}</li>`;
        },
        paragraph: (node: RichTextNode) => {
            if (!node.children) return '<br>';
            return `<p class="mb-5 last:mb-0">${node.children && Array.isArray(node.children) && node.children.length > 1 ? node.children.join('') : node.children}</p>`;
        },
        textStyle: (node: RichTextNode) => {
            return `<span>${node.text}</span>`;
        },
        link: (node: RichTextNode) => {
            if (!node.attrs) return;

            if (node.attrs.anchor)
                return `<a href="${node.attrs.href}#${node.attrs.anchor}" class="underline cursor-pointer">${node.children ? node.children : node.text}</a>`;

            switch (node.attrs.linktype) {
                case 'story':
                    return `<a href="${getUrlFromStoryblokLink(node.attrs)}" class="underline cursor-pointer">${node.children ? node.children : node.text}</a>`;

                case 'asset':
                    return `<a href="${node.attrs.href}" class="underline cursor-pointer" target="_blank" rel="noopener noreferrer">${node.children ? node.children : node.text}</a>`;

                case 'email':
                    return `<a href="mailto:${node.attrs.href}" class="underline cursor-pointer">${node.children ? node.children : node.text}</a>`;

                default:
                    return `<a href="${node.attrs.href}" class="underline cursor-pointer">${node.children ? node.children : node.text}</a>`;
            }
        },
    };

    return resolvers;
};
